@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: url("./fonts/Poppins-ExtraLightItalic.woff");
}

* {
  font-family: "Poppins-ExtraLightItalic";
  color: white;
}

body {
  background-color: rgb(0, 68, 68);
}

h1 {
  margin: 20px 0;
  font-size: 5vw;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submitButton {
  margin-top: 10px;
  font-size: 1.5rem;
  border: none;
  padding: 20px;
  border-radius: 4px;
  background-color: rgb(1, 126, 126);
  color: white;
  transition: 200ms;
}

.submitButton:hover {
  padding: 20px 30px;
  cursor: pointer;
  background-color: rgb(0, 214, 214);
}

.submitButton:disabled {
  background-color: gray;
  cursor: auto;
}

.mainBox {
  display: flex;
  background-color: rgb(0, 49, 49);
  flex-direction: column;
  align-items: center;
  width: 80%;
  border-radius: 5px;
  box-shadow: black 3px 3px 10px;
  padding: 30px;
}
